<template>
  <div class="nav-inner">
    <div class="nav-tab">
      <div style="width:1200px;margin:0 auto;">
        <div class="hotel-logo">
          <span class="logo-img" />
          <span>EasyKash</span>
        </div>
        <ul class="hotel-li">
          <li
            v-for="(item,index) in navData.list"
            :key="index"
            :class="navData.index === index ? 'select-item under-line': 'select-item'"
          >
            <a :href="item.link">{{ item.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navData: {
      type: Object,
      default: function() {
        return null
      }
    }
  },
  methods: {
    goAnchor(selector) {
      this.$router.push({ name: selector })
    }
  }
}
</script>

<style lang="scss" scope>
.nav-inner {
  min-width: 1200px;
  background: #fff;
  .nav-tab {
    height: 88px;
    align-items: center;
    // padding: 10px 0;
    .hotel-logo {
      float: left;
      height: 88px;
      line-height: 88px;
      span:nth-child(1) {
        display: inline-block;
        width: 44px;
        height: 44px;
        vertical-align:middle;
        background: url("~@/assets/images/loanImg/logo_icon_2.png") no-repeat center;
        background-size: contain;
      }
      span:nth-child(2){
        display: inline-block;
        font-size: 18px;
        font-weight: 700;
        vertical-align:middle;
        margin-left: 10px;
      }
    }
    .hotel-li {
      float: right;
      height: 100%;
      li {
        display: inline-block;
        position: relative;
        // width: 16.66%;
        padding: 0 20px;
        height: 100%;
        font-size: 16px;
        text-align: center;
        line-height: 88px;
        white-space:nowrap;
        cursor: pointer;
        a{
          color: #999999;
          text-decoration: none;
        }
        &.under-line{
          color: $color-FE6E0A;
          a{
            color: #333333;
          }
          &::after{
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 16px;
            content: '';
            width: 30px;
            height: 3px;
            background: $color-FE6E0A;
          }
        }
      }
    }
  }
}
// @media only screen and (max-width: 768px) {
//   .nav-tab {
//     height: 180px!important;
//     .hotel-li{
//       .select-item{
//         padding: 0 5px!important;
//       }
//     }
//   }
// }
</style>
